import React from "react"
import SEO from "../components/utility/SEO"
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import MuiLink from "../components/utility/MuiLink";

const useStyles = makeStyles(theme => ({
  box: {
    height: 200,
    width: 300,
    backgroundColor: '#303960',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  text: {
    color: theme.palette.common.white,
    fontSize: 24
  }
}));

const Printing = ({pageContext}) => {

  const categoryData = pageContext.productCategories

  const classes = useStyles();
  return (
    <>
      <SEO title="Εκτυπώσεις"/>
      <Container maxWidth={"lg"} style={{paddingTop: 24}}>
        <Grid container justify={"space-around"} style={{marginBottom: 8}}>
          {categoryData && categoryData.map((item, index) => {
            return (
              <MuiLink key={index} style={{textDecoration: 'none' }} to={`printing/${item.toLowerCase().replace(/ /g, '-')}`}>
                <Grid className={classes.box}>
                  <Typography variant='body1' className={classes.text}>
                    {item}
                  </Typography>
                </Grid>
              </MuiLink>
            )
          })}
        </Grid>
      </Container>
    </>
  )
}

export default Printing
